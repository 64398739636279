<template>
    <div class="u-drive-form-field">
        <div @click="addFile" :class="['u-drive-form-field-button', { 'u-drive-form-field-button-disabled' : fileLimitReached }]">
            <div>
                <i class="icon icon icon-upload-1 icon48 grey2"></i>
                <p>{{ formFieldLabel }}</p>
            </div>
        </div>

        <div class="u-thumbnail">
            <u-upload-select-file
                v-for="(file, index) in filesSent"
                :key="file.uuid || index"
                :file="file"
                :organization="organization"
                @removeFile="removeFile"
            ></u-upload-select-file>
        </div>
    </div>
</template>

<script>
import UUploadSelectFile from './UUploadSelectFile'

export default {
    components: { 
        UUploadSelectFile
    },

    props: {
        formField: {
            type: Object,
            default: null
        },

        filesSent: {
            type: Array,
            default: () => []
        },

        organization: {
            type: Number,
            default: null
        }
    },

    computed: {
        formFieldLabel() {
            return this.formField && this.formField.placeholder ? this.formField.placeholder : 'Anexar arquivo'
        },

        fileLimitReached() {
            if (!this.formField.options.maxFiles) {
                return this.filesSent.length == 1
            }

            return this.filesSent.length == this.formField.options.maxFiles 
        },

        buttonTextColor() {
            return this.fileLimitReached ? 'grey2' : 'blue5'
        },
    },

    methods: {
        addFile() {
            if (this.fileLimitReached) {
                return
            }

            this.$emit('openFileExplorer')
        },

        removeFile(file) {
            this.$emit('removeFile', file)
        }
    }
};
</script>

<style lang="scss" scoped>
    .u-drive-form-field-button {
        height: 5.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white-color;
        border: 1px dashed $blue5;
        cursor: pointer;
        transition: all .2s;

        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        img { 
            width: 30px;
        }

        &:hover {
            border: 1px solid $blue5;
        }

        &.u-drive-form-field-button-disabled {
            background-color: $grey3;
            border-color: $grey3;
            color: $grey2;
            cursor: default;
        }
    }

    .u-thumbnail { 
        max-width: 100%;
    }
</style>