<template>
    <div v-if="!canPostInModel" class="feed-editor">
        <div v-show="editorFocus && !isResponse" class="textarea-mask" @click="setShowEditor(false)"></div>

        <div v-if="!isResponse" class="feed-editor-close" @click="setShowEditor(true)">
            <div class="d-flex">
                <u-avatar :user="user" :size="5" class="mr-4"></u-avatar>
                <u-text class="feed-editor-close-placeholder">Escrever uma mensagem...</u-text>
            </div>
            
            <i class="icon icon-send icon16"></i>
        </div>

        <div 
            v-show="showEditor" 
            @click="editorFocus = true" 
            v-on-clickaway="closeEditor" 
            :class="['feed-editor-content', {'feed-editor-content-response' : isResponse}, {'feed-editor-content-main-editor' : !isResponse}]"
            :ref="refEditor"
        >
            <div v-if="!isResponse" class="feed-editor-header">
                <div>
                    <u-title size="2" color="grey6" :margin="false">Nova publicação</u-title>
                    <div class="feed-editor-header-card" @click="$refs.refFeedEditorProjectSelect.openSelect()">
                        <u-text ellipsis size="4" color="grey6" class="feed-editor-header-card-name">{{ project ? project.name : 'Vincular publicação' }}</u-text>
                        <i class="icon icon-down-arrow-filled icon8 grey6 ml-3"></i>
                    </div>
                </div>

                <a href="" @click.stop.prevent="setShowEditor(false)" class="mt-2 mr-2">
                    <i class="icon icon-close-x icon12 grey6 cursor-pointer"></i>
                </a>
            </div>
            <div   
                :class="['feed-text-box', {'focused' : editorFocus}]"
                v-shortkey="['ctrl', 'enter']" 
                @shortkey="newPost"
            >
                <u-avatar
                    :user="user"
                    :size="5"
                ></u-avatar>

                <div ref="editor"></div>
            </div>

            <div v-show="!isResponse || editorFocus" :class="['feed-create-post-footer', {'has-file' : file}, {'focused' : editorFocus}]" 
                :style="!isResponse ? 'height: 50px;' : ''"
            >
                <div v-if="file" class="footer-file">
                    <div class="image-box">
                        <div :class="['image-mask', {'is-document' : fileType == 'document'}]">
                            <img v-if="fileType == 'image'" ref="refImageThumb">
                            <img
                                v-else
                                :src="
                                    getThumbnailPath(
                                        file,
                                        preferences.organization.uuid,
                                        file.name.split('.').pop(),
                                        true
                                    )
                                "
                                :alt="file.name"
                                style="
                                    object-fit: cover;
                                    width: 30px !important;
                                    height: 30px !important;
                                    border-radius: 4px;
                                "
                            />
                        </div>
                        
                        <p
                            v-html="file.name"
                        ></p>
                    </div>

                    <div @click="clearImg">
                        <u-icon
                            icon="close"
                            colorClass="hover-grey5-red5"
                            class="clear-button"
                            :width="14"
                            :height="14"
                        ></u-icon> 
                    </div>
                </div>

                <div class="feed-actions" v-show="editorFocus">
                    <div style="display:flex; align-items:center;">
                        <div :id="toolbarId" class="ql-toolbar ql-snow">
                            <div style="justify-content: space-between;">
                                <span class="ql-formats">
                                    <button class="ql-bold"></button>
                                    <button class="ql-italic"></button>
                                    <button class="ql-underline"></button>
                                    <select class="ql-color" title="'Char color'"></select>
                                    <button
                                        class="ql-list ql-list-number"
                                        value="ordered"
                                    ></button>
                                    <button
                                        class="ql-list ql-list-ordered"
                                        value="bullet"
                                    ></button>
                                    <button class="ql-link"></button>
                                </span>
                            </div>
                        </div>
                    </div>
                    
                    <div style="display: flex; align-items: center;" class="button-send-post">           
                        <div style="margin-right: 10px;" @click="$refs.inputfile.click()"> 
                            <u-icon
                                icon="paperclip"
                                colorClass="hover-grey8-blue5"
                                class="option-icon"
                                :width="14"
                                :height="14"
                            ></u-icon>   
                        </div>

                        <input v-show="false" type="file" ref="inputfile" @change="selectImage">

                        <u-gif-picker
                            @select-gif="selectGif"
                            style="margin-right: 10px;"
                        ></u-gif-picker>

                        <div v-if="editorFocus" style="position: relative; z-index: 0;"> 
                            <u-emoji-picker 
                                @select-emoji="selectEmoji"
                            ></u-emoji-picker>
                        </div>

                        <div @click="newPost" style="margin-left: 10px;">
                            <u-icon
                                icon="send"
                                :colorClass="hasContent ? 'blue5' : 'hover-grey4-blue5'"
                                class="send-button"
                                :width="16"
                                :height="16"
                            ></u-icon> 
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- <div v-if="selectOpen && projectsFilter" v-on-clickaway="closeSelector" class="select-content">
                <div class="project-select" >
                    <div class="project" @click="clearProject">
                        <p> Não vincular </p>
                    </div>

                    <div @click="selectProject(project)"
                        class="project" 
                        v-for="(project, index) in projectsFilter" 
                        :key="index"
                    >
                        <p 
                            v-html="project.name"
                        ></p>
                    </div>
                </div>
            </div> -->
        </div>
        
        <feed-editor-project-select ref="refFeedEditorProjectSelect" @set-project="setProject"></feed-editor-project-select>  
    </div>
</template>

<script>
    import Quill from 'quill'
    import 'quill/dist/quill.snow.css'
    import { getThumbnailPath, hasPreference } from "@/assets/js/helpers/index.js";
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
    import { mixin as clickaway } from 'vue-clickaway';
    import FeedEditorProjectSelect from './FeedEditorProjectSelect'
    import UAvatar from '@/assets/js/components/UI/avatar/UAvatar'
    import UEmojiPicker from '@/assets/js/components/UI/pickers/UEmojiPicker'
    import UGifPicker from '@/assets/js/components/UI/pickers/UGifPicker'

    export default { 
        mixins: [clickaway],

        props: { 
            filteredProject: {
                required: false,
            },

            projectsFilter: { 
                required: false,
                type: [Array, Object]
            },

            toolbarId: {
                type: String,
                default: 'toolbar',
            },

            placeholder: {
                type: String,
                default: 'Escreva uma mensagem...',
            },

            isResponse: {
                type: Boolean,
                default: false,
            },

            parentId: {
                type: Number,
            },
        },

        components: { 
            FeedEditorProjectSelect,
            UAvatar,
            UEmojiPicker,
            UGifPicker,
        },

        mounted() {
            let vm = this
            let element = this.$refs.editor

            if (!element) {
                return
            }

            if (!this.isResponse) {
                this.showEditor = false
            }

            this.editor = new Quill(this.$refs.editor, {
                modules: {
                    toolbar: '#' + vm.toolbarId,
                },
                placeholder: vm.placeholder,
                theme: 'snow',
                bounds: ".ql-container",
                formats: [
                    'bold',
                    'underline',
                    'italic',
                    'list',
                    'size',
                    'link',
                    'mention',
                    'color',
                    'emoji',
                    'image',
                ],
            })

            this.editor.root.addEventListener('paste', function (e) {
                var clipboard_data = e.clipboardData

                var pasted_text = clipboard_data.getData('text')
                var pasted_html = clipboard_data.getData('text/html')

                if (!pasted_text && !pasted_html) {
                    var items = clipboard_data.items
                    
                    if (!items[0]) return
                    e.preventDefault()
                }
            })

            this.editor.on('text-change', function(delta, oldDelta, source) {
                vm.hasContent = (vm.editor.root.innerHTML != '<p><br></p>' && vm.editor.root.innerHTML != '<p></p>' && vm.editor.root.innerHTML != '') || vm.file
            });
        },

        data() { 
            return { 
                showEditor: true,
                editor: null,
                selectOpen: false,
                file: null,
                model: this.filteredProject ? 'projects' : 'contacts',
                project: this.filteredProject ? this.filteredProject : null,
                loading: null,
                editorFocus: false,
                iconColor: 'grey4',
                hasContent: false,
            }
        },

        computed: { 
            ...mapState('Feed', [
                'selectedProject',
            ]),

            ...mapGetters("Auth", {
                user: "getContact",
                preferences: "getPreferences"
            }),
            
            fileType() {
                var char = this.file.type.indexOf('/')
                var type = this.file.type.substring(0, char != -1 ? char : this.file.type.length)

                if (type == 'image') {
                    return 'image'
                }

                return 'document'
            },

            validatePost() {
                if (this.loading) return false

                if (this.file && !this.loading) return true

                return true
            },

            canPostInModel() { 
                if (!this.hasPreference('user', 'canPostToCards') && !this.hasPreference('user', 'canPostToContact')) { 
                    return false
                }

                if (!this.preferences.user.canPostToCards && this.model == 'projects') return true
                if (!this.preferences.user.canPostToContact && this.model == 'contacts') return true
            },

            setColorIcon() { 
                this.iconColor = 'blue5'
            },

            refEditor() {
                return !this.isResponse ? 'refMainEditor' : 'refCommentEditor'
            }
        },

        methods: {
            hasPreference,

            getThumbnailPath,

            ...mapActions("Feed", [
                "sendPost"
            ]),

            ...mapMutations('Feed', [
                'setSelectedProject',
            ]),

            closeEditor() { 
                if (!this.editorFocus || !this.isResponse) return

                this.editorFocus = false
            },

            selectProject(project) { 
                this.model = 'projects'
                this.project = project
                this.selectOpen = !this.selectOpen
            },

            clearProject() {
                this.model = 'contacts'
                this.project = null
                this.selectOpen = !this.selectOpen
            },

            selectImage() { 
                let vm = this
                this.file = this.$refs.inputfile.files[0];

                var reader = new FileReader();
                reader.onload = function(){
                    var dataURL = reader.result;

                    if (vm.$refs.refImageThumb) {      
                        var output = vm.$refs.refImageThumb;
                        output.src = dataURL;
                    }
                };

                reader.readAsDataURL(this.file); 
            },

            clearImg() {
                this.file = null
            },

            newPost() { 
                if (!this.validatePost) return

                this.loading = true

                let currentFile = new FormData();

                if (this.file) {
                    currentFile.append('file', this.file)
                }

                currentFile.append('model_id', this.project && this.project.id 
                    ? this.project.id 
                    : this.user.id
                )
                currentFile.append('model', this.model)

                let postContent = this.editor.root.innerHTML == null ||
                this.editor.root.innerHTML == '<p><br></p>'
                    ? null
                    : JSON.stringify(this.editor.getContents(), null, 2)
                if (postContent) currentFile.append('description', postContent)

                if (this.parentId && this.isResponse) {
                    currentFile.append('parent_id', this.parentId)
                }

                if ( this.file && this.file.size > 2097152 ) {
                    this.$toast.open({
                        message: 'Tamanho do arquivo inválido.',
                        type: 'error'
                    })

                    this.loading = false
                    this.file = null
                    return
                }
                
                this.sendPost(currentFile).then((data) => {
                    this.$emit('updatePosts', data)   
                    this.editor.setText('')
                    this.file = null             
                    this.loading = false
                    this.editorFocus = false

                    if (!this.isResponse) { 
                        this.setShowEditor(false)
                    }

                    if (!this.isResponse && this.selectedProject && this.project && this.selectedProject.id != this.project.id) {
                        this.setProject(null)
                        this.setSelectedProject(null)
                    }
                }).catch((response) => { 
                    if (response.error.response.status === 413) { 
                        this.$toast.open({
                            message: 'Tamanho do arquivo inválido.',
                            type: 'error'
                        })
                    } 
                })
            },

            closeSelector() { 
                this.selectOpen = false
            },

            selectEmoji(emoji) {
                if (!this.editorFocus) return

                const { index } = this.editor.getSelection(true) || { index: 0 }

                this.editor.insertText(index, emoji)
            },

            selectGif(gifUrl) {
                const { index } = this.editor.getSelection(true) || { index: 0 }
                const hasContent = this.editor.getText() != '\n'

                if (hasContent) {
                    this.editor.insertText(index, '\n')
                }

                this.editor.insertEmbed(
                    index + (hasContent ? 1 : 0),
                    'image',
                    gifUrl
                )

                this.$nextTick(() => {
                    this.editor.insertText(index + (hasContent ? 2 : 1), '\n ')
                    let selectionIndex = index + (hasContent ? 3 : 2)
                    this.editor.setSelection(selectionIndex, selectionIndex)
                })
            },

            setShowEditor(value) {
                this.editorFocus = value
                this.showEditor = value

                if (this.showEditor) {
                    this.$nextTick(() => {
                        this.editor.focus()
                    })
                }
                
                if (!this.showEditor && this.$refs.refFeedEditorProjectSelect) {
                    this.$refs.refFeedEditorProjectSelect.closeSelect()
                }
            },

            setProject(project) {
                if (project == null) { 
                    this.model = 'contacts'
                    this.project = null
                    return
                }

                this.model = 'projects'
                this.project = project
            }
        },

        watch: { 
            filteredProject() { 
                this.setProject(this.filteredProject)
            },
        }
    }
</script>

<style lang="scss" scoped>
.feed-editor { 
    width: 100%;
    background-color: $white-color;
    border-radius: 16px;
    position: relative;

    @media (max-width: 900px) {
        width: 100% !important;
        border-radius: 0px;
    }

    .textarea-mask {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        background-color: $grey9;
        opacity: 0.5;
        z-index: 2;
    }

    .feed-editor-close {
        height: 64px;
        color: $grey3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 $spacing-5;
        border-radius: 16px;
        transition: all .2s;
        cursor: pointer;

        &:hover {
            box-shadow: 0px 1px 1px rgba(16, 35, 51, 0.05);

            .feed-editor-close-placeholder {
                transition: color .2s;
                color: $grey6;
            }

            i {
                transition: color .2s;
                color: $grey3;
            }
        }
    }

    .project-selector-mask { 
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 2;
    }

    .select-content { 
        background-color: #FFF;
        position: relative;
        bottom: 20px;
    }

    .project-select { 
        padding-top: 20px;
        box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);
        background-color: $white-color;
        border-radius: 0 0 16px 16px;
        position: absolute;
        width: 670px;
        max-height: 250px;
        overflow-y: scroll;
        animation: 0.2s ease-out 0s 1 slideInFromTop;
        z-index: 2;

        @media (max-width: 900px) {
            width: 100%;
        }

        .project { 
            height: calc(48px - 40px);
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 20px 23px;
            border: 1px solid transparent;

            &:hover { 
                background-color: $grey0;
            }

            &:active { 
                background-color: $blue8;

                p { 
                    color: $white-color;
                }
            }

            p { 
                font-size: 15px;
                color: $grey8;
            }
        }

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background: #dad7d7;
        }
    }

    .feed-text-box { 
        padding: 12px 12px 0 12px;
        display: flex;
        min-height: calc(80px - 36px);
        height: auto;
        background-color: $white-color;
        border-radius: 12px 12px 0 0;

        &.focused { 
            position: relative;
            z-index: 500;
        }

        .feed-user-logo { 
            margin-top: 2px;
        }

        .text-field {
            margin-left: 6px;
            width: 100%;
            resize: none;
            outline: none;
            background-color: transparent;
            border-radius: 4px;
            border: 0;
            font-size: 15px;
            color: $grey8;
            padding: 5px 12px 12px 12px;
        }
    }

    .feed-create-post-footer { 
        display: flex;
        flex-direction: column;
        padding-right: $spacing-4;
        z-index: 3;

        &.focused { 
            background-color: $white-color;
            position: relative;
            border-radius: 0 0 16px 16px;
        }

        &.has-file { 
            height: 80px !important;
        }

        .feed-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;

            .button-project { 
                cursor: pointer;
                height: 100%;
                display: flex;
                align-items: center;
                -webkit-user-select: none;
                -moz-user-select: -moz-none;
                -ms-user-select: none;
                user-select: none;

                p { 
                    font-weight: 600;
                    font-size: 15px;
                    color: $grey6;
                    margin-right: 8px;
                }
            }

            .button-send-post { 
                cursor: pointer;
                
                p {
                    color: $blue5;
                }
            }
        }

        .footer-file {  
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 4px 12px;

            .image-box {
                display: flex;
                align-items: center;
                width: 100%;

                .image-mask { 
                    margin-right: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;
                    border-radius: 4px;
                    overflow: hidden;

                    img { 
                        height: 100%;
                    }
                }

                p { 
                    max-width: 80%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-size: 15px;
                    color: $grey5;
                }

            }

            .clear-button { 
                cursor: pointer;
            }
        }
    }

    .send-button {
        cursor: pointer;
    }
}

.feed-editor-content-main-editor {
    position: absolute;
    top: -55px;
    left: 0;
    right: 0;
    z-index: 10;

    .feed-text-box {
        min-height: 100px;

        &.focused {
            border-radius: 0 !important;
        }
    }
}

.feed-editor-header {
    display: flex;
    justify-content: space-between;
    padding: $spacing-4;
    border-bottom: 1px solid $grey1;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: $white-color;

    .feed-editor-header-card {
        display: flex;
        align-items: center;
        height: 20px;
        width: fit-content;
        padding: 0 $spacing-3;
        margin-top: $spacing-2;
        background-color: $grey1;
        border-radius: 4px;
        transition: all .2s;
        cursor: pointer;

        .feed-editor-header-card-name {
            max-width: 400px;
        }

        &:hover {
            background-color: $grey2;
        }
    }
}

.feed-editor-content-response {
    width: 100%;
    
    .feed-text-box {
        background-color: $grey1;
        padding: 6px;
        min-height: auto;
        border-radius: 20px;
        transition: all .2s;

        &:hover {
           background-color: $grey2; 
        }

        &.focused {
            min-height: calc(80px - 36px);
            border-radius: 16px 16px 0 0;
        }
    }

    .feed-create-post-footer.focused {
        border-right: 1px solid $grey1;
        border-left: 1px solid $grey1;
        border-bottom: 1px solid $grey1;
        border-radius: 0px 0px 16px 16px;
    }
}


@keyframes slideInFromTop {
    0% {
        transform: translateY(-30%);
    }
    
    100% {
        transform: translateY(0%);
    }
}

</style>